import axios from '../lib/axios';
import { constants } from '../helpers/constants';
import useAdaptor from '../lib/swr';
import { useContext } from 'react';
import { isAddress } from 'ethers/lib/utils.js';
import { DataRefreshContext } from '../components/refreshContext';

export const getUwWrapFetcher = ({
  collectionAddresses,
  walletAddress,
  limit = 10,
  page = 1,
  search = '',
  is_wrap,
  sort,
  selectedCollection,
  chainId,
  refreshDate,
}) => {
  const enabled = isAddress(walletAddress) && !collectionAddresses?.find((a) => !isAddress(a));

  const config = {
    url: `${constants.api.url_new}/nfts/getUwWrap`,
    method: constants.httpMethods.get,
    params: {
      collectionAddresses,
      walletAddress,
      limit,
      page,
      search,
      is_wrap,
      sort,
      selectedCollection,
      chainId,
      refreshDate,
    },
  };

  const fetcher = () =>
    axios(config).then((response) => {
      return response.data;
    });

  return { config, fetcher, enabled };
};

export default function useGetUwWrap({
  collectionAddresses,
  walletAddress,
  limit = 10,
  page = 1,
  search = '',
  is_wrap,
  sort,
  selectedCollection,
  chainId,
}) {
  const { refreshDate } = useContext(DataRefreshContext);
  const { config, enabled, fetcher } = getUwWrapFetcher({
    collectionAddresses,
    walletAddress,
    limit,
    page,
    search,
    is_wrap,
    sort,
    selectedCollection,
    chainId,
    refreshDate,
  });
  return useAdaptor(enabled ? axios.getUri(config) : null, fetcher);
}
