import erc721ABI from '../../helpers/erc721ABI';
import { useCallback, useEffect, useState } from 'react';
import { useAccount, useWalletClient, useNetwork, erc20ABI } from 'wagmi';
import { getContract, switchNetwork } from 'wagmi/actions';
import { getCdnUrl } from '../../lib/cdnUrl';
import { ImageFixedAO } from '../Image_fixed_aspect_ratio';
import { getSupportedChain } from '../../helpers/getSupportedChain';
import { useCatchTxError } from '../../hooks/useCatchTxError';
import uwManagerABI from '../../helpers/uwManagerABI';

export const UWUnwrapCollections = ({ collection }) => {
  const { fetchWithCatchTxError } = useCatchTxError();
  const [image, setImage] = useState(null);
  const [approved, setApproved] = useState(false);
  const { address: walletAddress } = useAccount();
  const { data: walletClient } = useWalletClient({
    chainId: parseInt(collection?.chain),
  });
  const { chain } = useNetwork();

  const supportedChain = getSupportedChain(collection?.chain);

  const nftContract = getContract({
    address: collection?.address,
    abi: erc721ABI,
    walletClient,
    chainId: parseInt(collection?.chain),
  });

  const managerContract = getContract({
    address: supportedChain?.uwManager,
    abi: uwManagerABI,
    walletClient,
    chainId: parseInt(collection?.chain),
  });

  const handleAddWallet = async () => {
    try {
      const dn404Info = await managerContract?.read.getWrappedCollection([collection?.address]);

      // Current network
      const currentChainId = chain?.id;
      console.log('Current Chain ID: ', currentChainId);

      if (parseInt(collection?.chain) !== currentChainId) {
        await switchNetwork({ chainId: parseInt(collection?.chain) });
      }

      //await switchNetwork({ chainId: parseInt(collection?.chain) });
      //const tx = await managerContract?.write.addWallet([walletAddress]);

      const erc20Contract = getContract({
        abi: erc20ABI,
        address: dn404Info[0],
        walletClient,
        chainId: parseInt(collection?.chain),
      });

      const symbol = await erc20Contract?.read.symbol();
      const decimals = await erc20Contract?.read.decimals();

      await walletClient.watchAsset({
        type: 'ERC20',
        options: {
          address: dn404Info[0],
          decimals: Number(decimals),
          symbol: symbol,
        },
      });

      //await fetchWithCatchTxError(tx);
    } catch (error) {
      console.log(error);
    }
  };

  const getApprove = useCallback(async () => {
    try {
      const isApprovedForAll = await nftContract?.read.isApprovedForAll([
        walletAddress,
        supportedChain?.uwVault,
      ]);

      setApproved(isApprovedForAll);
    } catch (error) {
      console.log(error);
    }
  }, [approved]);

  useEffect(() => {
    setImage(getCdnUrl(collection?.profilePhotoPath, 'thumbnail', collection?.updatedAt));
    getApprove();
  }, [collection, walletAddress]);

  return (
    <div>
      <div className='inline-flex w-full items-center justify-between gap-2'>
        <div className='min-h-[48px] min-w-[48px] rounded '>
          <ImageFixedAO image={image} alt={collection?.address} bottomRounded />
        </div>
        <div className='mr-auto flex w-fit flex-col justify-start gap-3 truncate'>
          <p className="-font-['Roboto'] truncate text-base font-bold">{collection?.name}</p>
          <div className="-font-['Roboto'] text-sm font-normal">
            {collection?.count > 1
              ? collection?.count + ' Unwrappable Items'
              : collection?.count + ' Unwrappable Item'}
          </div>
        </div>
        <button
          onClick={handleAddWallet}
          className={`rounded-xl -font-['Roboto'] flex h-[40px] w-[108px]  items-center bg-accent px-6 text-center text-base font-medium text-white hover:bg-accent-dark `}
          data-testid='bulkToolapprove'
        >
          Import ERC20
        </button>
      </div>
    </div>
  );
};
